<template>
  <div class="w-full h-full relative" id="container-tunnel-map">
      <ButtonsZoomInOut></ButtonsZoomInOut>

    <div
      class="absolute w-full h-full bg-black z-20 opacity-50"
      :class="{
        hidden: !cadastre.is_api_loading && map_is_loaded,
      }"
    ></div>

    <div
      v-if="cadastre.is_api_loading || !map_is_loaded"
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-30"
    >
      <LoaderPromy classes="w-6" :color="'#10AAAF'"></LoaderPromy>
    </div>
    <div class="absolute top-4 right-10 sm:right-4 z-10"><Tips /></div>
    <div class="absolute left-12 bottom-12 z-30 lg:z-10 lg:left-4">
      <MapType />
    </div>
    <div class="wrapper-map"><div id="mapbox"></div></div>

    <div
      v-if="showBtnParcelle"
      class="
        absolute
        top-0
        left-0
        sm:left-1/3 sm:-translate-x-1/3
        pl-12
        pt-10
        flex flex-col
        sm:pl-7 sm:pt-6
      "
    >
      <div class="font-bold text-3xl mb-2 sm:text-xl text-promy-blue-300">
        C’est bien votre parcelle ?
      </div>
      <div class="flex sm:flex-col space-x-3 sm:space-x-0 sm:space-y-3">
        <AggregateParcelle
          :form="form"
          :cadastre="cadastre"
          :map="map"
          class="sm:w-full"
          @setBatimentsAndSuperficie="setBatimentsAndSuperficie"
          ref="aggregateParcelleCrossTunnel"
        ></AggregateParcelle>
      </div>
    </div>
    <!-- <div
      class="absolute z-20 center-element bg-white p-4 rounded-lg"
      v-if="cadastre.error_parcelle_not_found"
    >
      {{ cadastre.error_parcelle_not_found }}
    </div> -->
  </div>
</template>
<script>
import Mapbox from '@/mixins/map/mapbox'
import batiment from '@/mixins/batiment'
import parcelle from '@/mixins/parcelle'
import { mapGetters } from 'vuex'
import AggregateParcelle from './Actions/AggregateParcelle'

export default {
  data() {
    return {
      map_is_loaded: false,
    }
  },
  components: {
    AggregateParcelle,
  },
  mixins: [Mapbox, batiment, parcelle],
  props: {
    showBtnParcelle: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapGetters({
      map: 'map/map',
      active_layer: 'map/active_layer',
      currentStep: 'Tunnel/GET_STEP_NUMBER',
    }),
  },
  mounted() {
    this.map_is_loaded = false
    if (!this.map) {
      if (!this.cadastre.is_api_loading && this.currentStep === 2) {
        this.loadMap()
          ? this.loadDataToMap()
          : this.map.on('load', this.loadDataToMap)
      }
    } else {
      this.map_is_loaded = true
      document.querySelector('#mapbox').replaceWith(this.map.getContainer())
      this.$emit('mapLoaded')
    }
  },
  methods: {
    checkMapIsLoaded() {
      if (window.screen.width <= 1024) {
        this.map.on('moveend', () => {
          this.map_is_loaded = true
          this.map.off('moveend')
        })
      } else this.map_is_loaded = true
    },
    setBatimentsAndSuperficie() {
      if (!this.cadastre.is_loaded_cross_revelation) {
        let own_parcelles = [
          this.form.informations.current_parcelle,
          ...this.form.informations.parcelles_voisines,
        ].filter((parcelle) => parcelle)

        this.form.informations.batiments = this.getAllBatimentsWithUniqueId(
          this.getFeaturesExist(this.cadastre.features_autour, own_parcelles),
          own_parcelles,
        )

        this.form.informations.superficie_totale = Math.round(
          this.$turf.area(this.$turf.featureCollection(own_parcelles)),
        )
        this.form.informations.superficie_construite = Math.round(
          this.$turf.area(
            this.$turf.featureCollection(this.form.informations.batiments),
          ),
        )
      }
    },
    loadDataToMap() {
      this.checkMapIsLoaded()
      if (
        !this.form.informations.current_parcelle ||
        this.cadastre.is_loaded_cross_gu ||
        this.cadastre.is_loaded_cross_revelation
      ) {
        this.cadastre.features_autour = this.getFeaturesAutour(
          this.cadastre.data,
        )
        this.cadastre.parcelles_autour = this.getParcellesAutour(
          this.cadastre.features_autour,
        )
        this.form.informations.current_parcelle = this.form.informations
          .current_parcelle
          ? this.form.informations.current_parcelle
          : this.cadastre.parcelles_autour[0]

        let own_parcelles = [
          this.form.informations.current_parcelle,
          ...this.form.informations.parcelles_voisines,
        ]

        this.setBatimentsAndSuperficie()
        let fc_own_parcelles = this.$turf.featureCollection(own_parcelles)
        this.fitBoundsGeojsonInMap(fc_own_parcelles)
        this.addParcellesAutour(this.cadastre.parcelles_autour)
        this.addParcellesSelected(
          [
            this.form.informations.current_parcelle,
            ...this.form.informations.parcelles_voisines,
          ],
          this.green_parcelle_selected,
        )
        this.cadastre.is_loaded_cross_gu = false
        this.cadastre.is_loaded_cross_revelation = false
      }
      this.$store.commit('map/MAP_STYLLE_LOADED', true)
      this.$emit('mapLoaded')
    },
    getFeaturesAutour(cadastre) {
      return cadastre.geospatial_autour.features.map((feature) => {
        feature.parcelle.properties.id = feature.parcelle.id
        feature.parcelle.properties.ref_cadastrale = this.getRefParcelle(
          feature.parcelle,
        )
        return feature
      })
    },
    getParcellesAutour(geospatial_autour) {
      return geospatial_autour.map(function (feature) {
        return feature.parcelle
      })
    },
  },
  watch: {
    'cadastre.is_api_loading': {
      deep: true,
      handler(is_loading) {
        if (!is_loading && this.currentStep === 2) {
          let is_map_loaded = this.loadMap()
          is_map_loaded
            ? this.loadDataToMap()
            : this.map.on('load', this.loadDataToMap)
        }
      },
    },
  },
  beforeDestroy() {
    if (this.cadastre.is_parcelle_changing) {
      this.$refs.changeParcelleCrossTunnel.addListenerClickChangeParcelle()
    } else if (this.cadastre.is_parcelle_aggregating) {
      this.$refs.aggregateParcelleCrossTunnel.addListenerClickAggregateParcelle()
    }
    this.$store.commit('map/MAP_STYLLE_LOADED', false)
  },
}
</script>
<style lang="scss" scoped>
.wrapper-map {
  @apply w-full;
  @screen lg {
    height: 70vh;
  }
  height: calc(100vh - 104px);
}

.center-element {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
</style>
